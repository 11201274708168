import React,{useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { graphql, useStaticQuery } from 'gatsby';
import { getBrText } from "../../helpers/helper.rendering";

const Commitment = () => {
    useEffect(() => {
        AOS.init({ duration : 2000});
    }, [])

    const { realEstate } = useStaticQuery(graphql`
     query {
         realEstate {
             sections{
                 about{
                    image
                    commitment{
                        title
                        paragraph
                    }
                 }

             }
         }
     }`)

    return realEstate.sections.about.commitment.length > 0 && (
        <section className="about-compromiso ">
            
            <div className="first-item">
                <div className="container-fluid">
                    <div className="row items">
                        <div data-aos="fade-left"  className="col-lg-6 col-6">
                            <h1 className="">{realEstate.sections.about.commitment[0].title}</h1>
                            <p  className="">
                                {getBrText(realEstate.sections.about.commitment[0].paragraph).map(element => (
                                    <>
                                        {element}
                                        {/* <br className="" /> */}
                                    </>
                                ))}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="second-item" style={{backgroundImage:'url(' + realEstate.sections.about.image + ')'}}>
                <div className="container-fluid">        
                    <div className="row items">
                        <div data-aos="fade-right" className="offset-6 col-lg-6 col-6">
                            <h1 className="">{realEstate.sections.about.commitment[1].title}</h1>
                            <p className="">
                                {getBrText(realEstate.sections.about.commitment[1].paragraph).map(element => (
                                    <>
                                        {element}
                                        {/* <br className="" /> */}
                                    </>
                                ))}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="third-item">
                <div className="container-fluid">        
                    <div className="row items">
                        <div data-aos="fade-left" className="col-lg-6 col-6">
                            <h1 className="">{realEstate.sections.about.commitment[2].title}</h1>
                            <p className="">
                            {getBrText(realEstate.sections.about.commitment[2].paragraph).map(element => (
                                <>
                                    {element}
                                    {/* <br className="" /> */}
                                </>
                            ))}
                        </p>
                        </div>
                    </div>
                </div>
            </div>

           
        </section>

    )
}

export default Commitment;